import { createRouter, createWebHashHistory } from "vue-router";

/**
    所有权限通用路由表 
    如首页和登录页和一些不用权限的公用页面
 */
const constantRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登陆', keepAlive: false },
        component: () => import('@/views/Login')
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },


    {
        path: '/s',
        name: '完成闯关',
        meta: { title: '完成闯关', keepAlive: false },
        component: () => import('@/views/Submit')
    },
    {
        path: '/loginOut',
        name: '错误提示',
        name: 'loginOut',
        meta: { title: '错误提示', keepAlive: false },
        component: () => import('@/views/loginOut')
    },

    //亲子坊
    {
        path: '/qzf/:code/',
        name: 'qzf',
        meta: { title: '登陆', keepAlive: false },
        component: () => import('@/views/qinzifang/Login')
    }
]
//异步挂载的路由
//动态需要根据权限加载的路由表 
export const asyncRoutes = [
    //{
    //    path: '/kid',
    //    component: Layout,
    //    redirect: '/kidInfo',
    //    name: 'kid',
    //    children: [{
    //        path: 'kidInfo',
    //        code: 1,
    //        kidValidFlag: [0, 1],
    //        name: '校园信息管理',
    //        component: () => import('@/views/Kid/KidInfo'),
    //        meta: {
    //            title: '校园信息管理', icon: 'example', roles: [],
    //            iconShow: true,
    //            department: '假日才艺部',
    //            person: '陈静',
    //            phone: '0756-2686578-2766',
    //            url: [{
    //                name: '',
    //                roles: '0,1',
    //                url: 'https://www.yuque.com/docs/share/eb00c8f9-0989-4ef9-a9ba-62bb33fee734?# 《如何录入校园信息、收费价格》'
    //            }]
    //        }
    //    }],
    //    alwaysShow: false
    //},
    { path: '*', redirect: '/404', hidden: true }
];


export const router = createRouter({
    //bas: '/EvaluationWeb/', //   /EvaluationWeb
    bas: '/', //   /EvaluationWeb
    history: createWebHashHistory(),
    routes: constantRoutes
})



export default router

////实例化vue的时候只挂载constantRouter
//const createRouter = () => new Router({
//    // mode: 'history', // require service support
//    scrollBehavior: () => ({ y: 0 }),
//    routes: constantRoutes
//})

//const router = createRouter()

//export function resetRouter() {
//    const newRouter = createRouter()
//    router.matcher = newRouter.matcher // reset router
//}

//export default router
